import { Component, OnInit ,TemplateRef} from '@angular/core';
import  '../../assets/main.js';
import { BsModalService, BsModalRef,ModalDirective } from 'ngx-bootstrap';
import { ServiceService } from '../service.service.js';

declare var carousel: any;

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
  thanksInfo:boolean=false
  donorThanksInfo:boolean=false
  volunteersListRef:BsModalRef
  registerListRef:BsModalRef
  voluntariesDetails:Object={}
  donerInfo:Object={}

  constructor(public Serviceservice:ServiceService, public modalService: BsModalService) { }

  ngOnInit() {
    carousel();
  }
  joinVolunteers(joinvolunteers: TemplateRef<any>){
    this.thanksInfo = false
    this.volunteersListRef = this.modalService.show(joinvolunteers, {		
      animated: true,		
      backdrop: 'static',
      class: 'modal-lg'
    });	
  }
  submitVolunteers(){
    this.Serviceservice.post_voluntaries_list(this.voluntariesDetails).subscribe(data =>{
      if(data.status="success"){
        this.thanksInfo = true
        setTimeout(function () {
          this.volunteersListRef.hide()
          this.voluntariesDetails={}
        }.bind(this), 3000); 
      }
    })
  }
  volunteersjoineeclose(){
    this.volunteersListRef.hide()
  }
  registerForDonate(registertoDonate: TemplateRef<any>){
    this.registerListRef = this.modalService.show(registertoDonate, {		
      animated: true,		
      backdrop: 'static',
      class: 'modal-lg'
    });	
  }
  submitDonerInfo(){
    this.Serviceservice.post_new_donor(this.donerInfo).subscribe(data =>{
      if(data.status="success"){
        this.donorThanksInfo = true
        setTimeout(function () {
          this.donerInfo={}
          this.registerListRef.hide()
        }.bind(this), 3000); 
      }
    })
  }
  donerInfoclose(){
    this.registerListRef.hide()
  }
}
