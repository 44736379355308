import { Component,TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef,ModalDirective } from 'ngx-bootstrap';
import { ServiceService } from './service.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  CurrentPage:string = "Home"
  thanksInfo:boolean = false
  volunteersListRef:BsModalRef
  voluntariesDetails:Object={}
  constructor(public Serviceservice:ServiceService, public modalService: BsModalService) { }
  title = 'shanthi';
  ChangeScreen(page){
    this.CurrentPage =page
  }
  joinVolunteers(joinvolunteers: TemplateRef<any>){
    this.thanksInfo = false
    this.volunteersListRef = this.modalService.show(joinvolunteers, {		
      animated: true,		
      backdrop: 'static',
      class: 'modal-lg'
    });	
  }
  submitVolunteers(){
    this.Serviceservice.post_voluntaries_list(this.voluntariesDetails).subscribe(data =>{
      if(data.status="success"){
        this.thanksInfo = true
        setTimeout(function () {
          this.volunteersListRef.hide()
          this.voluntariesDetails={}
        }.bind(this), 3000); 
      }
    })
  }
  volunteersjoineeclose(){
    this.volunteersListRef.hide()
  }
}
