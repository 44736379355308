import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ServiceService {

  constructor(private http: HttpClient) { }
    get_orphan_home_list(param){
      return this.http.get<any>("http://orphanhome.herokuapp.com/orphanhome/homelist?name="+param);
    }
    post_orphan_home_list(data){
      return this.http.post<any>("http://orphanhome.herokuapp.com/orphanhome/homelist",data);
    }
    post_voluntaries_list(data){
      return this.http.post<any>("http://orphanhome.herokuapp.com/voluntaries/newvoluntaries",data);
    }
    get_voluntaries_list(){
      return this.http.get<any>("http://orphanhome.herokuapp.com/voluntaries/newvoluntaries");
    }
    post_new_donor(data){
      return this.http.post<any>("http://orphanhome.herokuapp.com/donation/newdonor",data);
    }
}
