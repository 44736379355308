import { Component, OnInit } from '@angular/core';
import  '../../assets/main.js';
declare var carousel: any;

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  lat: number = 12.963235;
  lng: number = 80.210787;
  constructor() { }

  ngOnInit() {
    carousel()
  }

}
