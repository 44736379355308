import { Component, OnInit,TemplateRef } from '@angular/core';
import { ServiceService } from '../service.service';
import { BsModalService, BsModalRef,ModalDirective } from 'ngx-bootstrap';
import  '../../assets/main.js';
import { Alert } from 'selenium-webdriver';
declare var carousel: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  providers: [BsModalService]
})
export class HomeComponent implements OnInit {
  orphaneHomeList:any=[]
  searchValue:string=""
  donorThanksInfo:boolean =false
  postCharityReg:Object={}
  voluntariesDetails:Object={}
  donerInfo:Object={}
  thanksInfo:boolean=false
  charityRef:BsModalRef
  charityListRef:BsModalRef
  volunteersListRef:BsModalRef
  registerListRef:BsModalRef

  constructor(public Serviceservice:ServiceService, public modalService: BsModalService,) { }
 
  ngOnInit() {
    this.valuechange();
    carousel();
  }

  valuechange(){
    this.Serviceservice.get_orphan_home_list(this.searchValue).subscribe(data =>{
      if(data.status == "success"){
        this.orphaneHomeList = data.data
        console.log(data,"datatsdasdsdsads")
      }else{
        alert("cannot process your request")
      }

    })
  }
  sbmitCharityRegister(){
    
    console.log(this.postCharityReg,"postCharityRegpostCharityReg")
    if(!this.postCharityReg['orphan_home_name'] || !this.postCharityReg['mail_id'] || !this.postCharityReg['orphan_proprietary_name'] || !this.postCharityReg['orphan_home_address'] || !this.postCharityReg['mobile_number1'] ){
      alert('Please fill all mandtaory');
      return
    }
    this.postCharityReg["active"] = false
    this.Serviceservice.post_orphan_home_list(this.postCharityReg).subscribe(data =>{
      console.log(data,"sdfsfsfsd")
      if(data.status == "success"){
        this.orphaneHomeList.push(this.postCharityReg)
        this.closeCharityRegisterModal()
      }
    })
  }
  submitVolunteers(){
    if(!this.voluntariesDetails['voluntaries_name'] || !this.voluntariesDetails['voluntaries_email'] || !this.voluntariesDetails['voluntaries_phone_number'] || !this.voluntariesDetails['voluntaries_address']){
      alert('Please fill all mandtaory');
      return
    }
    this.Serviceservice.post_voluntaries_list(this.voluntariesDetails).subscribe(data =>{
      if(data.status="success"){
        this.thanksInfo = true
        setTimeout(function () {
          this.volunteersListRef.hide()
        }.bind(this), 3000); 
      }
    })
  }
  charityRegister(charityregistertemplate: TemplateRef<any>){
    this.charityRef = this.modalService.show(charityregistertemplate, {		
      animated: true,		
      backdrop: 'static',
      class: 'modal-lg'
    });	
  }
  closeCharityRegisterModal(){
    this.charityRef.hide()
    this.postCharityReg={}
  }
  charityList(charitylist: TemplateRef<any>){
    this.charityListRef = this.modalService.show(charitylist, {		
      animated: true,		
      backdrop: 'static',
      class: 'modal-lg'
    });	
  }
  charityListclose(){
    this.charityListRef.hide()
  }
  joinVolunteers(joinvolunteers: TemplateRef<any>){
    this.thanksInfo = false
    this.volunteersListRef = this.modalService.show(joinvolunteers, {		
      animated: true,		
      backdrop: 'static',
      class: 'modal-lg'
    });	
  }
  volunteersjoineeclose(){
    this.volunteersListRef.hide()
  }
  registerForDonate(registertoDonate: TemplateRef<any>){
    this.registerListRef = this.modalService.show(registertoDonate, {		
      animated: true,		
      backdrop: 'static',
      class: 'modal-lg'
    });	
  }
  submitDonerInfo(){
    this.Serviceservice.post_new_donor(this.donerInfo).subscribe(data =>{
      if(data.status="success"){
        this.donorThanksInfo = true
        setTimeout(function () {
          this.registerListRef.hide()
        }.bind(this), 3000); 
      }
    })
  }
  donerInfoclose(){
    this.registerListRef.hide()
  }
}
